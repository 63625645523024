body {
  padding-bottom: 2lh;
  font-size: 9.2pt !important;
}

header {
  display: none;
}

h2 {
  font-size: 1.5em;
  line-height: 1.2em;
}

h3 {
  margin-top: 10px;
}

#author {
  display: none;
}

article .article-section img {
  box-shadow: none;
}

article h3 {
  position: static !important;
}

[data-role="read_more"] {
  max-height: none !important;
}

[data-role="read_more"] [data-role="content"] {
  -webkit-mask-image: none !important;
  mask-image: none !important;
}

[data-role="read_more"] [data-role="expand"] {
  display: none;
}

.references {
  display: none !important;
}

footer {
  display: body;
}

/*# sourceMappingURL=index.563c05c2.css.map */
