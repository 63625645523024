body { font-size: 9.2pt !important; padding-bottom: 2lh; }
header { display: none; }
h2 { line-height: 1.2em; font-size: 1.5em; }
h3 { margin-top: 10px; }
#author { display: none; }
article .article-section img { box-shadow: none; }
article h3 { position: static !important; }
[data-role=read_more] { max-height: none !important; }
[data-role=read_more] [data-role=content] { -webkit-mask-image: none !important; mask-image: none !important; }
[data-role=read_more] [data-role=expand] { display: none; }
.references { display: none !important; }
footer { display: body; }
